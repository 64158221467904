<template>
  <q-card style="width: 400px;">
    <q-card-section>
      <q-form
        ref="form"
        class="q-gutter-md"
      >
        <div class="row justify-between">
          <div class="col-12">
            <q-input
              v-model="payload.name"
              type="text"
              label="Name"
              dense
              lazy-rules
              :rules="[(val) => $rules('Name', val).required]"
            />
          </div>
        </div>
        <div class="row justify-between">
          <div class="col-12">
            <q-select
              v-model="payload.type"
              :options="typeOptions"
              option-value="value"
              option-label="text"
              label="Type"
              dense
              lazy-rules
              emit-value
              map-options
              :rules="[(val) => $rules('Type', val).required]"
            />
          </div>
        </div>
      </q-form>
    </q-card-section>

    <q-separator />

    <q-card-actions justify="space-around">
      <div class="col text-right">
        <q-btn-group>
          <q-btn outline size="sm" color="accent" @click="cancel">Cancel</q-btn>
          <q-btn outline size="sm" color="primary" @click="submit">{{ isEditMode ? 'Update' : 'Save' }}</q-btn>
        </q-btn-group>
      </div>
    </q-card-actions>
  </q-card>
</template>

<script>
import { ref } from '@vue/reactivity'
import { computed, getCurrentInstance, watchEffect } from '@vue/runtime-core'
import { useStore } from 'vuex'

export default {
  props: {
    mode: {
      type: String,
      default: 'new'
    },
    mainId: {
      type: String,
      default: undefined,
      required: true
    },
    data: {
      type: Object,
      default: () => ({})
    }
  },
  setup (props, ctx) {
    const instance = getCurrentInstance()
    const self = instance.appContext.config.globalProperties
    const store = useStore()

    const payload = ref({
      name: '',
      type: ''
    })

    const typeOptions = computed(() => store.getters?.ddSkills)
    const isEditMode = computed(() => props.mode.toLowerCase() === 'edit')

    watchEffect(() => {
      if (isEditMode) {
        payload.value = JSON.parse(JSON.stringify(props.data))
      }
    })

    const cancel = () => {
      ctx.emit('onCancel')
    }

    const submit = async () => {
      const valid = await instance?.refs.form.validate()

      if (!valid) return

      let res
      if (isEditMode.value) {
        res = await self.$api.projectTech.update(props.mainId, props.data?.id, payload.value)
      } else {
        res = await self.$api.projectTech.create(props.mainId, payload.value)
      }

      if (res?.success) {
        store.dispatch('setProfileFull')
      }

      ctx.emit('postSubmit')
    }

    return {
      payload,
      submit,
      cancel,
      isEditMode,
      typeOptions
    }
  }
}
</script>
